import moment from "moment";

export const dateFormatting = (value, type) => {
  let date = "";

  switch (type) {
    case "short":
      date = moment(value).format("YYYY-MM-DD");
      break;
    case "long":
      date = moment(value).format("YYYY-MM-DD HH:mm:ss");
      break;
    default:
      date = value;
  }
  return date;
};

export default {
  dateFormatting,
};
